* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.test {
  transform: translateX(150vw);
}

.slide-in-enter {
  opacity: 0;
  transform: translateX(150vw);
}
.slide-in-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-in-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-in-exit-active {
  transform: translateX(-150vw);
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-spinner {
  font-size: 1rem;
  color: #fff;
  animation-name: spinner;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.onsched-calendar {
  min-width: 0 !important;
}

.onsched-theme-primary.sm .onsched-availability .onsched-calendar-col .day {
  padding: 0;
}

.onsched-theme-primary.sm .onsched-availability .onsched-service-name {
  text-align: center;
}

#appointments {
  display: none;
}
/* 
i {
  font-size: 0.8rem;
} */

.linkedIn {
  opacity: 0.8;
  transition: opacity 200ms;
}

.linkedIn:hover {
  opacity: 0.9;
}

.linkedIn:active {
  opacity: 1;
}
