.header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    background-color: #FFF;
    position: fixed;
    top: 0;
    padding: 0 1rem;
    border-bottom: 1px solid #C4C4C4;
    z-index: 1000;
}

.logo {
    width: 7rem;
}