.button {
    width: 100%;
    height: 2.7rem;
    /* padding: 0; */
    border: none;
    border-radius: 5px;
    background-color: #03B1D8;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 500;
    transition: opacity 200ms, color 200ms ;
}

.button.plain{
    width: 20%;
    background-color: transparent;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

.button.success{
    width: 100%;
    background-color: transparent;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

.button.plain:hover {
    color: #03B1D8;
}

.button:hover {
    opacity: 0.9;
    cursor: pointer;
}