.container-main {
  margin: 6rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #21274f;
}

.dashboard-heading {
  /* margin: 1rem 0 0 0; */
  font-size: 1.5rem;
  font-weight: 600;
}

.profile-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  border: 1px solid #21274f;
}
.logout {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0 1rem;
}

.profile-img-container > i {
  font-size: 3rem;
  color: #21274f;
}

.profile-image {
  width: 100%;
}

.bio {
  margin: 2.5rem 0 0 0;
  padding: 0 2rem;
  width: 100%;
  max-width: 50rem;
  line-height: 2rem;
  letter-spacing: 1px;
}

.block {
  margin: 2.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-heading {
  color: #03b1d8;
}

.big-text {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.small-text {
  font-size: 0.8rem;
  color: #03b1d8;
}

.popup-container {
  display: flex;
  align-items: center;
}

.page-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loading > img {
  width: 5rem;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  animation-name: page-loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.calendar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 0.8rem;
}

.calendar div {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.calendar i {
  font-size: 2rem;
  color: gray;
}

@keyframes page-loading {
  from {
    opacity: 10%;
  }
  to {
    opacity: 25%;
  }
}

@media only screen and (min-width: 300px) {
  .page-loading > img {
    width: 6.5rem;
  }
}
