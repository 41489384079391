.footer-main {
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #C4C4C4;
    margin: 5rem 0 0 0;
    opacity: 0.9;
    color: #21274F;
    font-size: 0.8rem;
}
.dashboard-footer-main {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25%;
    border-top: 1px solid #C4C4C4;
    margin: 5rem 0 0 0;
    opacity: 0.9;
    color: #21274F;
    font-size: 0.8rem;
}

.dashboard-footer-main i {
    font-size: 1.5rem;
    color: rgb(80, 80, 80);
}