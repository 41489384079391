.payment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.payment-portal-logo {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: 300ms;
}

.payment-portal-logo.loading {
  opacity: 30%;
}

.payment-portal-logo.loading:hover {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  opacity: 30%;
}

.payment-portal-logo:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}

/* Chrome, Safari, Edge, Opera */
.acc-number::-webkit-outer-spin-button,
.acc-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.acc-number[type="number"] {
  -moz-appearance: textfield;
}
