.sign-up-main {
  margin: 2rem 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-main.home {
  margin: 8rem 0 0 0;
}

.sign-up-main > h1 {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  width: 80%;
  color: #21274f;
}

.sign-up-main.wide > h1 {
  width: 80%;
  margin-bottom: 2rem;
}

.form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-label {
  margin: 3rem 0;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: transparent;
  /* opacity: 40%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-label > i {
  color: #03b1d8;
  font-size: 3rem;
}

.page-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loading > img {
  width: 5rem;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  animation-name: page-loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode:both;
  animation-timing-function: ease-in-out;
}

@keyframes page-loading {
  from {
    opacity: 10%;
  }
  to {
    opacity: 25%;
  }
}

@media only screen and (min-width: 300px) {
  .page-loading > img {
    width: 6.5rem;
  }
}

@media only screen and (min-width: 576px) {
  .form {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) {
  .form {
    width: 40%;
  }
}
