.form-control {
  margin-bottom: 2rem;
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  background-color: #dddddd;
  border: none;
  padding: 0.5rem;
  transition: box-shadow 200ms;
}

.form-control-forgot {
  margin-bottom: 0;
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  background-color: #dddddd;
  border: none;
  padding: 0.5rem;
  transition: box-shadow 200ms;
}

.input {
  width: 88%;
  height: 3rem;
  font-size: 0.8rem;
  background-color: #dddddd;
  border: none;
  padding: 0.5rem;
  transition: box-shadow 200ms;
}

.input-button {
  height: 3rem;
  width: 12%;
  padding: 0 0.5rem;
  border: none;
  border-radius: 0px;
  background-color: #dddddd;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  transition: opacity 200ms;
}

.input-button > i {
  color: #21274f;
}

.input-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  height: fit-content;
}

.form-select {
  margin-bottom: 2rem;
  width: 100%;
  height: fit-content;
  font-size: 0.8rem;
  border: none;
  transition: box-shadow 200ms;
}

.form-select.expertise {
  margin-bottom: 0rem;
}

.form-select-avail {
  margin-bottom: 2rem;
  width: 80%;
  margin: 0 2rem;
  height: fit-content;
  font-size: 0.8rem;
  border: none;
  transition: box-shadow 200ms;
}

.form-control.textarea {
  height: 7rem;
}

.form-control:focus-visible,
.form-text:focus-visible,
.input:focus-visible {
  border: none;
  outline: none;
  box-shadow: 0px 0px 12px 3px #a5c3d3;
}
